@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TT Norms Pro';
  src:
    url('../fonts/TTNormsPro-Regular.woff2') format('woff2'),
    url('../fonts/TTNormsPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms Pro';
  src:
    url('../fonts/TTNormsPro-Medium.woff2') format('woff2'),
    url('../fonts/TTNormsPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
}

#__next {
  min-height: 100vh; /* fallback */
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

/* Customize the scrollbar */
.scroll-wrap::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

.scroll-wrap::-webkit-scrollbar-thumb {
  background: rgba(22, 73, 116, 0.24);
  border-radius: 5px;
}

.scroll-wrap {
  scrollbar-width: thin;
  scrollbar-color: rgba(22, 73, 116, 0.24), rgba(22, 73, 116, 0);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*:focus {
  outline: none;
}

/* Hide arrows/spinners for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}

.box-vertical {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
